<template>
    <div>
        <card-header title="New Document" icon="fa-plus"/>

        <card-body>
            <document-form :doc="newDocument"/>
            <card-list>
                <container-list-item v-if="newDocument.file">
                    <data-row title="File">{{newDocument.file_name}}</data-row>
                    <data-row title="Hash">{{newDocument.file}}</data-row>
                    <data-row title="Type">{{newDocument.file_mime}}</data-row>
                </container-list-item>
                <container-list-item padded>
                    <file-pond
                        name="file"
                        ref="pond"
                        label-idle="Drop or click to add document..."
                        :allow-multiple="false"
                        :allowRevert="false"
                        :files="files"
                        @init="handleFilePondInit"
                        @processfile="handleFilePondProcess"
                        :server="{
                                url: apiUrl,
                                timeout: 7000,
                                process: {
                                    url: 'api/users/documents/upload',
                                    method: 'POST',
                                    headers: {
                                        'Authorization': token
                                    },
                                    withCredentials: false
                                }
                            }"
                    />
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newDocument.subject" @click="submit"><i class="fas fa-plus mr-3"></i>New Document</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardBody from "../../TIER/components/CardBody";

    import {client as http} from '../../http_client';
    import DocumentForm from "@/cards/customers/DocumentForm";

    // Import Vue FilePond
    import vueFilePond from 'vue-filepond';

    // Import FilePond styles
    import 'filepond/dist/filepond.min.css';

    // Import FilePond plugins
    // Please note that you need to install these plugins separately

    // Import image preview plugin styles
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    // Import image preview and file type validation plugins
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
    import ContainerListItem from "@/TIER/components/ContainerListItem";
    import DataRow from "@/TIER/components/DataRow";
    import {mapGetters} from "vuex";
    import CardList from "@/TIER/components/CardList";

    // Create component
    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        props: ['card', 'props'],
        components: {CardList, DataRow, ContainerListItem, DocumentForm, CardBody, CardFooter, CardHeader, FilePond},
        data: function () {
            return {
                apiUrl: (location.hostname === 'localhost') ? 'http://localhost:3000/' : 'https://portal.tennisventures.net:3000/',
                newDocument: {
                    file: null,
                    file_name: null,
                    file_mime: null
                },
                question: {},
                files: []
            };
        },
        computed: {
            ...mapGetters(['token']),
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/users/' + this.props.userId + '/documents', {user_id: this.props.userId, ...this.newDocument}, {force: true}).then(response => {
                    this.$reloadCard('customer-documents', {userId: this.props.userId});
                    this.$reloadCard('customer', {userId: this.props.userId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    console.log('An error occurred.');
                    this.$emit('loading', false);
                });
            },
            handleFilePondInit: function() {
                // console.log('FilePond has initialized');
                // FilePond instance methods are available on `this.$refs.pond`
            },

            handleFilePondProcess: function(err, response) {
                // Todo this doesn't handle errors
                // FilePond:processfile - Finished processing a file, if the detail object contains an error property, something went wrong

                let file = JSON.parse(response.serverId);

                this.newDocument.file = file.file_name;
                this.newDocument.file_name = file.file_original_name;
                this.newDocument.file_mime = file.file_mime;

                setTimeout(function() {
                    this.$refs.pond.removeFile(file.id);
                }.bind(this), 3000);

            },
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new document? Unsaved changes will be lost.');
        }
    };
</script>

<style>
    .filepond--credits {
        display: none !important;
    }
</style>
